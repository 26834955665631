import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import { Events } from '@/services/EventsDataService'
import SysEvent, { SysEventEventType } from '@/types/SysEvent'

type dataReturnType = { events: any; totalPages: number; error: any; }

export default class theEventList extends Vue {
  error: any = null
  private events: SysEvent[] = []
  private eventtypes: SysEventEventType[] = []
  private currentListSortingOrder = 'event_start:asc'
  private oldSort = ''
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  toDaysDate = new Date().toISOString()
  eventHeld = `&event_start_gte=${this.toDaysDate}`
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  filterByTimeValue = 'Kommende arrangementer'
  filterByTimeOptions: string[] = ['Alle arrangementer', 'Kommende arrangementer', 'Afholdte arrangementer']
  eventSearchInputValue = ''
  eventSearchTerm = ''
  filterByEventTypeValue = { text: 'Alle', value: '0' }
  filterByEventTypeOptions: { text: string; value: string }[] = [{ text: 'Alle', value: '0' }]
  eventType = ''

  readonly name : string = 'theEventList'
  data (): dataReturnType {
    return {
      events: this.events,
      error: this.error,
      totalPages: this.totalPages
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveEvents()
  }

  @Watch('filterByTimeValue')
  onFilterByTimeValueChange (status: string) : void {
    if (status === 'Alle arrangementer') {
      this.eventHeld = ''
    }
    if (status === 'Kommende arrangementer') {
      this.eventHeld = `&event_start_gte=${this.toDaysDate}`
    }
    if (status === 'Afholdte arrangementer') {
      this.eventHeld = `&event_start_lt=${this.toDaysDate}`
    }

    this.retrieveEvents()
  }

  @Watch('filterByEventTypeValue')
  onFilterByEventTypeChange (newVal : string) : void {
    if (this.filterByEventTypeValue.value === '0') {
      this.eventType = ''
    } else {
      this.eventType = `&event_type_id.id=${this.filterByEventTypeValue.value}`
      console.log('EventTypeFilter: ' + this.eventType)
    }

    this.retrieveEvents()
  }

  public toEventInfo (id: number, eventName: string, eventDate: string) : void {
    const date = new Date(eventDate).toLocaleDateString('da-DK').replaceAll('.', '-')
    const eventSlug = id.toString() + '_' + this.convertToSlug(eventName) + '-' + date

    console.log(eventSlug)
    this.$router.push({ name: 'Event', params: { slug: eventSlug } })
  }

  public findEvent () : void {
    console.log('Searching events')
    this.eventSearchTerm = this.eventSearchInputValue
    this.retrieveEvents()
  }

  public turnToNewPage (pageChange : number) {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveEvents()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveEvents()
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'event_start:desc'
      this.oldSort = ''
      this.eventSearchInputValue = ''
      this.eventSearchTerm = ''
      this.currentPage = 1
      this.eventHeld = ''
      this.filterByTimeValue = 'Alle arrangementer'
      this.filterByEventTypeValue.text = 'Alle'
      this.eventType = ''
    }
    if (sortBy === 'eventname') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'eventtype') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_type_id.event_type_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_type_id.event_type_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'dato') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'event_start:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'event_start:asc'
        this.oldSort = ''
      }
    }

    this.retrieveEvents()
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public fillEventTypeOptions () : void {
    this.filterByEventTypeOptions = [{ text: 'Alle', value: '0' }]
    for (const eventtype of this.eventtypes) {
      this.filterByEventTypeOptions.push({ text: eventtype.event_type_titel, value: (eventtype.id === undefined ? '0' : Number(eventtype.id).toString()) })
    }
  }

  public retrieveEventTypes () : void {
    console.log('Fetching active event type from API.')

    Events.EventTypesDataService.getAll('', null, 'event_type_status=true')
      .then((response) => {
        this.eventtypes = response.data
        console.log(response.data)
        this.fillEventTypeOptions()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveEvents () : void {
    console.log('Counting all active events')

    Events.EventsDataService.getCount(`event_status=true${this.eventHeld}${this.eventType}`)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })

    console.log('Fetching events data from API.')

    if (this.eventSearchTerm.length > 0) {
      Events.EventsDataService.findBySearchTerm(true, true, this.eventSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `event_status=true${this.eventHeld}${this.eventType}`)
        .then((response) => {
          this.events = response.data
          console.log(response.data)

          this.forceRerenderEventList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Events.EventsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `event_status=true${this.eventHeld}${this.eventType}`)
        .then((response) => {
          this.events = response.data
          console.log(response.data)

          this.forceRerenderEventList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public forceRerenderEventList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  async mounted () : Promise<void> {
    this.retrieveEvents()
    this.retrieveEventTypes()
  }
}
