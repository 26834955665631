
import { Options, Vue } from 'vue-class-component'
import theEventList from '@/components/Frontend/EventList/index.vue'

@Options({
  components: {
    theEventList
  }
})

export default class Eventlist extends Vue {}
